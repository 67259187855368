import { PaymentItemProfessionalType } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC } from 'react';

interface ProfessionalsItemProps {
  itemAmount: number;
  professional: PaymentItemProfessionalType;
  refundedAmount?: number;
}

const ProfessionnelsItem: FC<ProfessionalsItemProps> = ({
  itemAmount,
  professional: { amount, label, reversals, transfer, transferFees },
  refundedAmount,
}) => {
  const solde = !transfer
    ? amount - ((refundedAmount || 0) * amount) / itemAmount
    : undefined;

  const reversalsAmount =
    reversals && reversals.length > 0
      ? reversals.reduce(
          (acc: number, { amount: a }: { amount: number }) => acc + a,
          0,
        )
      : undefined;

  return (
    <div className="flex items-start space-x-2">
      <span className="flex-1">{label}</span>

      <div className="flex flex-col items-end">
        {refundedAmount === itemAmount && (
          <div className="text-light-800">Remboursé</div>
        )}
        {refundedAmount !== itemAmount && transfer && (
          <span className="flex space-x-1">
            {undefined !== reversalsAmount &&
              reversalsAmount > 0 &&
              (transfer.amount - reversalsAmount) / 100 > 0 && (
                <span className="text-light-800">
                  {(transfer.amount - reversalsAmount) / 100}€
                </span>
              )}
            <span
              className={classnames('whitespace-nowrap text-light-800', {
                'line-through':
                  undefined !== reversalsAmount && reversalsAmount > 0,
              })}
            >
              <span className="mr-3 text-[10px]">
                {Math.round(transferFees * 10000) / 100}% de frais
              </span>
              <span>{transfer.amount / 100} €</span>
            </span>
          </span>
        )}
        {refundedAmount !== itemAmount && !transfer && (
          <span className="flex space-x-1">
            {undefined !== refundedAmount &&
              refundedAmount > 0 &&
              undefined !== solde && <span>{solde} €</span>}
            <span
              className={classnames('whitespace-nowrap', {
                'line-through': undefined !== solde && solde !== amount,
              })}
            >
              {amount} €
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default ProfessionnelsItem;
