// import { DataSection } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';
import { Field } from 'redux-form';

// import apparence from './apparence.json';
// import colors from './colors.json';
// import dimensions from './dimensions.json';
// import display from './display.json';
// import position from './position.json';
// import spacing from './spacing.json';
// import text from './text.json';
import ViewModal from '~/components/View/Modal';
import AttributesRender from '~/datas/Attributes/Render';

interface ModalProps {
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
  formName: string;
  name: string;
  params?: { [key: string]: any };
  title: string;
}

const Modal: FC<ModalProps> = function ({
  closeOnClick,
  formName,
  name,
  params,
  title,
  ...props
}) {
  return (
    <ViewModal
      buttons={{
        right: [{ label: 'Fermer', onClick: closeOnClick }],
      }}
      closeOnClick={closeOnClick}
      title={title}
    >
      <Field
        component={AttributesRender}
        name={name}
        {...props}
        formName={formName}
        params={params}
      />
    </ViewModal>
  );
};

export default Modal;
