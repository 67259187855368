import { DocumentType, MediaType, ModelType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import FirebaseFirestore, {
  deleteDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes } from 'firebase/storage';
import { mergeWith } from 'lodash';
import mime from 'mime';
import { customAlphabet } from 'nanoid';
import { WatchOptionsProp } from 'packages/innedit/datas/functions';

import { functions, storage } from '../../../config/firebase';
import ModelEspace, { ModelEspaceProps } from '../../Model/Espace';

export interface ModelEspaceMediaProps<T extends ModelType>
  extends ModelEspaceProps<T> {
  parentCollectionName?: string;
  parentField?: string;
  parentId?: string;
}

class Media extends ModelEspace<MediaType> {
  public parentField: string;

  constructor(props: Omit<ModelEspaceMediaProps<MediaType>, 'collectionName'>) {
    super({
      ...props,
      collectionName: 'medias',
      orderDirection: props.orderDirection || 'desc',
      orderField: props.orderField || 'datetime',
    });

    this.parentField = props.parentField || 'medias';
  }

  public async uploadBlob(
    blob: Blob,
    name: string,
    parent?: string,
  ): Promise<DocumentType<MediaType>> {
    const token = customAlphabet(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
      20,
    )();
    const extension = (mime.getExtension(blob.type) ?? 'txt').toLowerCase();
    const path = `${this.espaceId}/${token}.${extension}`;

    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, blob);
    if (!snapshot) {
      throw new Error(
        "problème lors de l'enregistrement du fichier dans le bucket",
      );
    }

    const { bucket, fullPath, size } = snapshot.metadata;

    return this.create({
      bucket,
      fullPath,
      parent,
      size,
      label: name,
      lastModified: dayjs().valueOf(),
      type: blob.type,
    });
  }

  public async uploadFile(file: File): Promise<DocumentType<MediaType>> {
    // const extension = (/[^./\\]*$/.exec(image.name) || [''])[0];

    const extension = (
      (/[^./\\]*$/.exec(file.name) || [''])[0] ?? mime.getExtension(file.type)
    ).toLowerCase();

    const token = customAlphabet(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
      20,
    )();
    const path = `${this.espaceId}/${token}.${extension}`;

    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);

    if (!snapshot) {
      throw new Error(
        "problème lors de l'enregistrement du fichier dans le bucket",
      );
    }

    const { bucket, fullPath, size } = snapshot.metadata;

    return this.create({
      bucket,
      fullPath,
      size,
      label: file.name,
      lastModified: file.lastModified,
      parentField: this.parentField,
      type: file.type,
    });
  }

  public async uploadUrl(
    str: string,
    accept?: string,
  ): Promise<DocumentType<MediaType>> {
    let url;

    try {
      url = new URL(str);
    } catch (error) {
      throw new Error("le chemin n'est pas une url valide");
    }

    if (url.protocol !== 'http:' && url.protocol !== 'https:') {
      throw new Error("le chemin n'est pas une url valide");
    }

    const file = await fetch(str, {
      headers: {
        'User-Agent':
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/74.0.3729.157 Safari/537.36',
      },
    }).then(r => r.blob());

    if (!file) {
      throw new Error("le fichier n'existe pas");
    }
    // TODO faire le traitement pour vérifier que le type de fichier est accepté

    const extension = (
      (/[^./\\]*$/.exec(str) || [''])[0] ?? mime.getExtension(file.type)
    ).toLowerCase();
    const token = customAlphabet(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
      20,
    )();
    const path = `${this.espaceId}/${token}.${extension}`;

    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);

    if (!snapshot) {
      throw new Error(
        "probleme lors de l'enregistrement du fichier dans le bucket",
      );
    }

    const { bucket, fullPath, size } = snapshot.metadata;

    return this.create({
      bucket,
      fullPath,
      size,
      label: url.href,
      parentField: this.parentField,
      type: file.type,
    });
  }

  public async deletePhoto(fullPath: string): Promise<void> {
    // TODO voir pour ajouter une action qui supprime le fichier sur le serveur
    const constraints = [where('fullPath', '==', fullPath)];
    if (this.espaceId) {
      constraints.push(where('espaceId', '==', this.espaceId));
    }
    const q = query(this.getCollectionRef(), ...constraints);

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty && 1 === querySnapshot.size) {
      return deleteDoc(querySnapshot.docs[0].ref);
    }

    throw new Error("La photo n'existe pas");
  }

  public async extractFromUrl(url: string): Promise<string> {
    const extractMedia = httpsCallable(functions, 'extractMedia');

    return extractMedia({
      url,
      espaceId: this.espaceId,
      parentCollectionName: this.parentCollectionName,
      parentField: this.parentField,
      parentId: this.parentId,
    }).then(result => result.data as string);
  }

  // public rotatePhoto(fullPath: string, angle = 90): Promise<string> {
  //   const actionData = new ActionData({ espaceId: this.espaceId });
  //
  //   return actionData
  //     .create({
  //       attributes: {
  //         angle,
  //         fullPath,
  //       },
  //       type: 'media-rotate',
  //     })
  //     .then(
  //       documentReference =>
  //         new Promise(resolve => {
  //           const unsubscribe = documentReference.onSnapshot(
  //             (documentSnapshot: FirebaseFirestore.DocumentSnapshot) => {
  //               if (
  //                 documentSnapshot.exists &&
  //                 documentSnapshot.get('destinationPath')
  //               ) {
  //                 const destinationPath =
  //                   documentSnapshot.get('destinationPath');
  //                 documentSnapshot.ref.delete();
  //                 unsubscribe();
  //                 resolve(destinationPath);
  //               }
  //             },
  //           );
  //         }),
  //     );
  // }

  public watch(
    next: (docs: DocumentType<MediaType>[]) => void,
    options?: WatchOptionsProp,
  ): FirebaseFirestore.Unsubscribe {
    const newOptions = { ...options };

    newOptions.wheres = mergeWith(
      {
        parentField: this.parentField,
      },
      newOptions.wheres,
    );

    return super.watch(next, newOptions);
  }

  watchNotUse(
    next: (snapshot: DocumentType<MediaType>[]) => void,
    options?: {
      espaceId?: string;
    },
  ): FirebaseFirestore.Unsubscribe {
    const constraints: FirebaseFirestore.QueryConstraint[] = [
      where('docId', '==', ''),
      where('deleted', '==', false),
    ];

    if (options && options.espaceId) {
      constraints.push(where('espaceId', '==', options.espaceId));
    }

    constraints.push(orderBy('createdAt', 'desc'));
    constraints.push(limit(100));
    const q = query(this.getCollectionRef(), ...constraints);

    return onSnapshot(q, {
      next: snapshot =>
        next(
          snapshot.docs.map(d => ({
            id: d.id,
            ...d.data(),
          })),
        ),
    });
  }
}

export default Media;
