import { DocumentType, ThoughtType } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import { ThoughtData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import HOCGroup from '~/components/Group/HOC';
import { DataCommentsCrudProps } from '~/datas/props';

import Item from './Item';

const DataCommentsCrud: FC<DataCommentsCrudProps> = ({
  attributesName,
  display,
  espaceId,
  formName,
  params,
  parentCollectionName,
  parentId,
  prefixPathname = '..',
  title,
  ...props
}) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState<DocumentType<ThoughtType>[]>();

  const commentData = new ThoughtData({
    espaceId,
    parentCollectionName,
    parentId,
    parentField: 'comments',
  });

  useEffect(() => {
    let isMounted = true;
    let unsub: (() => void) | undefined;

    commentData.watch(newDocs => {
      if (isMounted) {
        setComments(newDocs);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [parentId]);

  const handleAddOnClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if ('create' === params?.type) {
      await dispatch(submit(formName));
      await navigate(
        `/espaces/${espaceId}/${parentCollectionName}/${parentId}/update/`,
        { replace: true },
      );
    }

    const data = commentData.initialize();
    commentData
      .create(data)
      .then(comment =>
        navigate(
          `/espaces/${espaceId}/comments/${parentCollectionName}/${parentId}/${comment.id}/update`,
        ),
      )
      .catch(console.error);
  };

  const handleEditOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      await navigate(
        `/espaces/${espaceId}/comments/${parentCollectionName}/${parentId}/${id}/update`,
      );
    }
  };

  const handleDeleteOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const i = parseInt(
      String(event.currentTarget.getAttribute('data-index')),
      10,
    );

    if (
      Number.isInteger(i) &&
      comments &&
      window.confirm('Confirmation de la suppression du commentaire')
    ) {
      const document = comments[i];
      commentData.delete(document.id);
    }
  };

  return (
    <HOCGroup
      addOnClick={handleAddOnClick}
      attributesName={attributesName}
      display={display}
      formName={formName}
      params={params}
      title={title}
      {...props}
    >
      {undefined === comments && <p>Chargement en cours</p>}
      {comments !== undefined && 0 === comments.length && (
        <p>Aucun commentaire</p>
      )}

      {comments !== undefined && 0 < comments.length && (
        <>
          {comments.map((comment, idx) => (
            <Item
              key={comment.id}
              deleteOnClick={handleDeleteOnClick}
              document={comment}
              editOnClick={handleEditOnClick}
              index={idx}
            />
          ))}
        </>
      )}
    </HOCGroup>
  );
};

export default DataCommentsCrud;
