import React, {
  FC,
  KeyboardEvent,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

import Button from '~/components/Button';
import Input from '~/components/Input';
import IconClose from '~/icons/Close';
import IconSearch from '~/icons/Search';

const OrderProductSearchModal: FC<{ value?: string }> = function ({
  value: defaultValue,
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [q, setQ] = useState<string | undefined>(defaultValue);
  const [, setResults] = useState<SearchResponse<any>[]>();

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQ(value);

    if ('' === value) {
      setResults(undefined);
    }
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if ('Enter' === e.key) {
      e.preventDefault();

      // search();
    }
  };

  const handleResetOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setQ('');
    setResults(undefined);
  };

  const handleSearchOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // search();
  };

  return (
    <div ref={ref} className="flex">
      <div className="relative flex-1">
        <Input
          className=" rounded-r-none pr-[36px]"
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          placeholder="Recherche des produits"
          value={q}
        />
        {q && (
          <Button
            className="absolute right-[13px] top-[13px] w-[16px]"
            color="tertiary"
            iconLeft={IconClose}
            onClick={handleResetOnClick}
            variant="link"
          />
        )}
      </div>

      <Button
        className="rounded-l-none"
        color="neutral"
        iconLeft={IconSearch}
        onClick={handleSearchOnClick}
      />
    </div>
  );
};

export default OrderProductSearchModal;
