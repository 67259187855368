import { ModelType } from '@innedit/innedit-type';
import FirebaseFirestore, {
  doc,
  getDoc,
  orderBy,
  where,
} from 'firebase/firestore';

import { firestore } from '../config/firebase';

export class InneditError extends Error {
  readonly code: string;

  readonly name = 'InneditError';
  constructor(code: string, message: string) {
    super(message);

    this.code = code;
  }
}

export interface WhereProps {
  [key: string]:
    | boolean
    | number
    | string
    | number[]
    | string[]
    | ObjectWhereProps
    | ObjectWhereProps[];
}

export interface FindOptionsProp<T extends ModelType> {
  limit?: number;
  orderDirection?: 'asc' | 'desc';
  orderField?: keyof T;
  startAfter?: string;
  startAt?: string;
  wheres?: WhereProps;
}

export interface SearchOptionsProp {
  language?: string;
  limit?: number;
  orderField?: string;
  orderDirection?: 'desc' | 'asc';
  page?: number;
  params?: { [key: string]: any };
  responseFields?: string[];
  wheres?: WhereProps;
}

export interface WatchOptionsProp {
  limit?: number;
  orderDirection?: 'asc' | 'desc';
  orderField?: string;
  startAfter?: FirebaseFirestore.QueryDocumentSnapshot;
  wheres?: WhereProps;
}

export interface ObjectWhereProps {
  operator?: FirebaseFirestore.WhereFilterOp;
  value: boolean | number | string | number[] | string[];
}

// const exemples: WhereProps = {
//   attr: true,
//   attr2: 'string',
//   attr3: 1,
//   attr4: {
//     operator: '==',
//     value: 0,
//   },
//   attr5: [
//     {
//       value: true,
//     },
//     {
//       value: 1,
//     },
//     {
//       value: 'string',
//     },
//     {
//       operator: '>',
//       value: true,
//     },
//     {
//       operator: '<',
//       value: 1,
//     },
//     {
//       operator: '<',
//       value: [1, 2],
//     },
//     {
//       operator: '<',
//       value: 'string',
//     },
//     {
//       operator: '<',
//       value: ['string', 'string2'],
//     },
//   ],
//   attr6: [1, 2],
//   attr7: ['string', 'string2'],
// };

export function updateConstraints(
  constraints: FirebaseFirestore.QueryConstraint[],
  options?: {
    orderDirection?: FirebaseFirestore.OrderByDirection;
    orderField?: string;
    wheres?: WhereProps;
  },
): FirebaseFirestore.QueryConstraint[] {
  const localOrderField: string[] = [];
  const localOrderDirection: ('asc' | 'desc')[] = [];
  const localConstraints = [...constraints];
  if (options?.wheres && Object.keys(options.wheres).length > 0) {
    const { wheres } = options;

    Object.keys(wheres).forEach(key => {
      // Pour chaque clé on regarde si il y a plusieurs conditions aka array
      // 0. is array
      if (!Array.isArray(wheres[key])) {
        // 1. boolean
        // 2. number
        // 3. string
        // 4. object
        switch (typeof wheres[key]) {
          case 'boolean':
          case 'number':
          case 'string': {
            // C'est une condition classique == quelque chose
            localConstraints.push(where(String(key), '==', wheres[key]));
            break;
          }

          case 'object': {
            // C'est un object where donc il peut y avoir des options
            let tmpValue = (wheres[key] as ObjectWhereProps).value;

            const tmpOperator =
              (wheres[key] as ObjectWhereProps).operator ||
              (Array.isArray((wheres[key] as ObjectWhereProps).value)
                ? 'in'
                : '==');

            // Si ce n'est pas un champs non ordonnable
            if (
              !['==', 'array-contains-any', 'in', 'not-in'].includes(
                tmpOperator,
              )
            ) {
              localOrderField.push(key);
              if (['<', '<='].includes(tmpOperator)) {
                localOrderDirection.push('desc');
              } else {
                localOrderDirection.push('asc');
              }
            }

            // si c'est array-contains-any, in ou not-in, la valeur est automatiquement un array
            if (
              ['array-contains-any', 'in', 'not-in'].includes(tmpOperator) &&
              !Array.isArray(tmpValue)
            ) {
              tmpValue = [tmpValue as any];
            }

            localConstraints.push(where(String(key), tmpOperator, tmpValue));
            break;
          }
          default:
        }
      } else {
        // 5. number[]
        // 6. string[]
        // 7. object[]
        switch (typeof (wheres[key] as any[])[0]) {
          case 'number':
          case 'string': {
            localConstraints.push(where(String(key), 'in', wheres[key]));
            break;
          }
          case 'object': {
            (wheres[key] as ObjectWhereProps[]).forEach(objectWhere => {
              let tmpValue = (objectWhere as ObjectWhereProps).value;
              const tmpOperator =
                (objectWhere as ObjectWhereProps).operator ||
                (Array.isArray((objectWhere as ObjectWhereProps).value)
                  ? 'in'
                  : '==');

              if (
                !['==', 'array-contains-any', 'in', 'not-in'].includes(
                  (objectWhere as ObjectWhereProps).operator as string,
                )
              ) {
                localOrderField.push(key);
                if (['<', '<='].includes(tmpOperator)) {
                  localOrderDirection.push('desc');
                } else {
                  localOrderDirection.push('asc');
                }
              }

              if (
                ['array-contains-any', 'in', 'not-in'].includes(tmpOperator) &&
                !Array.isArray(tmpValue)
              ) {
                tmpValue = [tmpValue as any];
              }

              localConstraints.push(where(String(key), tmpOperator, tmpValue));
            });
          }
        }
      }
    });
  }

  localOrderField.forEach((order, idx) => {
    localConstraints.push(orderBy(order, localOrderDirection[idx]));
  });

  if (options?.orderField) {
    localConstraints.push(
      orderBy(options.orderField, options?.orderDirection || 'asc'),
    );
  }

  return localConstraints;
}

export const findById = (
  collectionName: string,
  id: string,
  options?: {
    espaceId?: string;
  },
): Promise<FirebaseFirestore.DocumentSnapshot> =>
  getDoc(doc(firestore, collectionName, id)).then(documentSnapshot => {
    if (!documentSnapshot.exists()) {
      throw new Error(
        `Le document de la collection ${collectionName} qui a pour id "${id}" n'existe pas`,
      );
    }

    if (
      options?.espaceId &&
      documentSnapshot.get('espaceId: this.espaceId,') !== options.espaceId
    ) {
      throw new Error("Ce document n'appartient pas à cet espace");
    }

    if (documentSnapshot.get('deleted')) {
      throw new Error('Ce document a été supprimé');
    }

    return documentSnapshot;
  });

export const getAlgoliaFilters = (wheres: WhereProps): string => {
  const filters: string[] = [];

  if (wheres && Object.keys(wheres).length > 0) {
    Object.keys(wheres).forEach(key => {
      let operator = ':';
      let value = wheres[key];
      if (undefined !== value) {
        if (Array.isArray(wheres[key])) {
          (wheres[key] as ObjectWhereProps[]).forEach(filtre => {
            if ('boolean' === typeof filtre.value) {
              value = Boolean(filtre.value);
              operator = ':';
            }
            if (Number.isInteger(filtre.value)) {
              value = filtre.value;
              switch (filtre.operator) {
                case '<':
                  operator = '<';
                  break;

                case '>':
                  operator = '>';
                  break;

                case '==':
                default:
                  operator = '=';
              }
            }
            filters.push(`${key}${operator}${value}`);
          });
        } else if ('object' === typeof wheres[key]) {
          const filtre: ObjectWhereProps = wheres[key] as ObjectWhereProps;

          switch (typeof filtre.value) {
            case 'boolean': {
              value = Boolean(filtre.value);
              operator = ':';
              filters.push(`${key}${operator}${value}`);
              break;
            }

            case 'number': {
              if (Number.isInteger(filtre.value)) {
                value = filtre.value;
                switch (filtre.operator) {
                  case '<':
                    operator = '<';
                    break;

                  case '>':
                    operator = '>';
                    break;

                  case '==':
                  default:
                    operator = '=';
                }
              }

              filters.push(`${key}${operator}${value}`);
              break;
            }

            case 'object': {
              if (Array.isArray(filtre.value)) {
                const tmp = filtre.value.map(v => `${key}:${v}`).join(' OR ');

                filters.push(`(${tmp})`);
              }

              break;
            }

            default: {
              value = filtre.value;
              operator = ':';
              filters.push(`${key}${operator}${value}`);
            }
          }
        } else {
          if (Number.isInteger(value)) {
            operator = '=';
          }
          filters.push(`${key}${operator}${value}`);
        }
      }
    });
  }

  return filters.join(' AND ');
};

export const getTypesenseFilters = (wheres: WhereProps): string => {
  const filters: string[] = [];

  if (wheres && Object.keys(wheres).length > 0) {
    Object.keys(wheres).forEach(key => {
      let operator = ':=';
      let value = wheres[key];
      if (undefined !== value && value !== '') {
        if (Array.isArray(wheres[key])) {
          (wheres[key] as ObjectWhereProps[]).forEach(filtre => {
            if ('boolean' !== typeof filtre.value || filtre.value) {
              if (Number.isInteger(filtre.value)) {
                value = filtre.value;
                switch (filtre.operator) {
                  case '<':
                    operator = ':<';
                    filters.push(`${key}${operator}${value}`);
                    break;

                  case '>':
                    operator = ':>';
                    filters.push(`${key}${operator}${value}`);
                    break;

                  case '==':
                    operator = ':=';
                    filters.push(`${key}${operator}${value}`);
                    break;

                  default:
                    console.error(
                      "cette opérateur n'est pas pris en charge",
                      filtre.operator,
                    );
                }
              }
            }
          });
        } else if ('object' === typeof wheres[key]) {
          const filtre: ObjectWhereProps = wheres[key] as ObjectWhereProps;

          switch (typeof filtre.value) {
            case 'boolean': {
              if (value) {
                operator = ':=';
                filters.push(`${key}${operator}${value}`);
              }
              break;
            }

            case 'number': {
              if (Number.isInteger(filtre.value)) {
                value = filtre.value;
                switch (filtre.operator) {
                  case '<':
                    operator = ':<';
                    filters.push(`${key}${operator}${value}`);
                    break;

                  case '>':
                    operator = ':>';
                    filters.push(`${key}${operator}${value}`);
                    break;

                  case '==':
                    operator = ':=';
                    filters.push(`${key}${operator}${value}`);
                    break;

                  default:
                    console.error(
                      "cette opérateur n'est pas pris en charge",
                      filtre.operator,
                    );
                }
              }

              break;
            }

            case 'object': {
              if (Array.isArray(filtre.value)) {
                const tmp = filtre.value.map(v => `\`${v}\``).join(',');

                operator = ':';
                filters.push(`${key}${operator}[${tmp}]`);
              }

              break;
            }

            default: {
              value = filtre.value;
              switch (filtre.operator) {
                case '<':
                  operator = ':<';
                  filters.push(`${key}${operator}${value}`);
                  break;

                case '>':
                  operator = ':>';
                  filters.push(`${key}${operator}${value}`);
                  break;

                case '==':
                  operator = ':=';
                  filters.push(`${key}${operator}${value}`);
                  break;

                default:
                  console.error(
                    "cette opérateur n'est pas pris en charge",
                    filtre.operator,
                  );
              }
            }
          }
        } else if ('boolean' !== typeof value || value) {
          if (Number.isInteger(value)) {
            operator = ':=';
          }
          filters.push(`${key}${operator}${value}`);
        }
      }
    });
  }

  return filters.join(' && ');
};
