import { OrderProductType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import Button from '~/components/Button';

interface ItemNoEditableProps {
  espaceId: string;
  item: OrderProductType;
}

const ItemNoEditable: FC<ItemNoEditableProps> = ({ espaceId, item }) => (
  <div className="grid grid-cols-3 items-start gap-3">
    <div className="col-span-2 flex space-x-2">
      {item.thumbnail && (
        <img alt={item.label} className="h-[40px]" src={item.thumbnail} />
      )}
      <div className="flex flex-col justify-start">
        {item.id ? (
          <Button
            className="w-auto justify-start text-start"
            to={`/espaces/${espaceId}/produits/${item.id}/update/`}
            variant="link"
          >
            {item.label}
          </Button>
        ) : (
          <strong>{item.label}</strong>
        )}
        <div className="flex items-center">
          {item.isPhysical ? 'Produit physique' : ''}
        </div>
      </div>
    </div>

    <div className="col-span-1 flex items-center justify-end">
      {item.quantity || 1} x {item.price}€
    </div>
  </div>
);

export default ItemNoEditable;
