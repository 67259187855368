import { DocumentType, PriceType, sortPrices } from '@innedit/innedit-type';
import { Box } from 'packages/formidable';
import React, { FC, SyntheticEvent } from 'react';

import Item from './Item';

const verifCompatibiliteDocs = (docs?: DocumentType<PriceType>[]) => {
  const repartitions = docs?.reduce((acc, d) => {
    if (undefined === acc[d.type]) {
      acc[d.type] = {
        [d.currency ?? 'EUR']: 0,
      };
    } else if (undefined === acc[d.type][d.currency ?? 'EUR']) {
      acc[d.type][d.currency ?? 'EUR'] = 0;
    }

    acc[d.type][d.currency ?? 'EUR'] += 1;

    return acc;
  }, {} as { [type: string]: { [currency: string]: number } });

  return repartitions
    ? Object.keys(repartitions).some(type =>
        Object.keys(repartitions[type]).some(
          currency => repartitions[type][currency] > 1,
        ),
      )
    : false;
};

const PricesList: FC<{
  docs: DocumentType<PriceType>[];
  editPathname: string;
  removeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  title?: string;
}> = ({ docs, editPathname, removeOnClick, title }) => {
  const nonValide = verifCompatibiliteDocs(docs);

  return (
    <Box>
      {(nonValide || title) && (
        <div>
          {nonValide && (
            <span className="float-right mb-6 text-danger-500">
              Prix non compatibles entre eux
            </span>
          )}
          {title && <span>{title}</span>}
        </div>
      )}

      {docs.length > 0 &&
        docs
          .sort(sortPrices)
          .map((doc, index) => (
            <Item
              key={doc.id || index}
              doc={doc}
              editPathname={editPathname}
              removeOnClick={removeOnClick}
            />
          ))}
    </Box>
  );
};

export default PricesList;
