import React, { FC, SyntheticEvent } from 'react';

import Modal from '~/components/View/Modal';
import OrderProductSearchModal from '~/datas/Orders/Products/Search/Modal';

// const usePreviousQ = (value?: string): string | undefined => {
//   const ref = useRef<string>();
//
//   useEffect(() => {
//     ref.current = value;
//   });
//
//   return ref.current;
// };

const OrderProductSearch: FC<{
  closeOnClick: (event?: SyntheticEvent<HTMLButtonElement>) => void;
}> = function ({ closeOnClick }) {
  return (
    <Modal
      buttons={{
        left: [
          {
            disabled: true,
            label: '0 produit sélectionné',
          },
        ],
        right: [
          {
            label: 'Annuler',
          },
          {
            disabled: true,
            label: 'Ajouter',
          },
        ],
      }}
      closeOnClick={closeOnClick}
      title="Tous les produits"
    >
      <OrderProductSearchModal />
    </Modal>
  );
};

export default OrderProductSearch;
