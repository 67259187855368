import { DocumentType, StockType } from '@innedit/innedit-type';
import { DataField, DataProps } from 'packages/formidable';
import { StockData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import ButtonGroup from '~/components/Button/Group';
import { FieldTemplate } from '~/components/Field';
import HOCGroup from '~/components/Group/HOC';
import Input from '~/components/Input';
import IconChevron from '~/icons/Chevron';
import IconMinus from '~/icons/Minus';
import IconPlus from '~/icons/Plus';

export interface FieldProps {
  label: string;
  name: string;
  type: string;
}

export interface DataProduitsInventaireProps
  extends Omit<DataProps, 'componentType'> {
  adjustLabel?: string;
  espaceId: string;
  display?: 'inside' | 'group';
  docCollectionName: string;
  docId: string;
  title?: string;
}

const DataProduitsInventaire: FC<DataProduitsInventaireProps> = ({
  adjustLabel,
  espaceId,
  display,
  docCollectionName,
  docId,
  title,
}) => {
  const [adjustedQty, setAdjustedQty] = useState<number>(0);
  const [docs, setDocs] = useState<DocumentType<StockType>[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    let unsub: () => void;
    if (docId) {
      setIsLoading(true);
      const stockData = new StockData({
        espaceId,
        parentCollectionName: docCollectionName,
        parentId: docId,
      });
      unsub = stockData.watch(newDocs => {
        if (isMounted) {
          setDocs(newDocs);
          setIsLoading(false);
        }

        return true;
      });
    }

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId, docCollectionName, docId]);

  const handleAdjustOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const stockData = new StockData({
      espaceId,
      parentCollectionName: docCollectionName,
      parentId: docId,
    });

    setAdjustedQty(0);

    const data = stockData.initialize({
      quantity: adjustedQty,
    });

    await toast.promise(stockData.create(data), {
      error: "Problème lors de l'enregistrement de l'ajustement",
      pending: "Enregistrement de l'ajustement en cours",
      success: 'Ajustement de la quantité réussi',
    });

    setAdjustedQty(0);
  };

  const handleAddOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAdjustedQty(oldQty => oldQty + 1);
  };

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setAdjustedQty(parseInt(String(event.currentTarget.value), 10));
  };

  const handleRemoveOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setAdjustedQty(oldQty => oldQty - 1);
  };

  const quantity =
    docs && docs.length ? docs.reduce((acc, doc) => acc + doc.quantity, 0) : 0;

  return (
    <HOCGroup
      customInfos={
        docId && (
          <Button
            className="absolute right-0 top-0 min-h-0 whitespace-nowrap"
            to="../stocks"
            variant="sub-action"
          >
            Voir l&apos;historique
          </Button>
        )
      }
      display={display}
      title={title || 'Quantité'}
    >
      {isLoading && <p className="m-0">Chargement en cours</p>}
      {!docId && (
        <DataField
          componentType="input"
          labelShow={false}
          name="qtyAvailable"
          placeholder="Quantité disponible"
          type="number"
        />
      )}
      {docs && (
        <>
          <div className="flex flex-1 space-x-3">
            <FieldTemplate className="flex-1">{quantity}</FieldTemplate>
            <FieldTemplate>
              <ButtonGroup className="flex-1">
                <Input
                  className="flex-1"
                  onChange={handleOnChange}
                  type="number"
                  value={adjustedQty}
                />
                <Button
                  color="neutral"
                  iconClassName="h-[16px] w-[16px]"
                  iconLeft={IconPlus}
                  onClick={handleAddOnClick}
                  variant="field"
                />
                <Button
                  color="neutral"
                  iconClassName="h-[16px] w-[16px]"
                  iconLeft={IconMinus}
                  onClick={handleRemoveOnClick}
                  variant="field"
                />
              </ButtonGroup>
            </FieldTemplate>
          </div>

          {adjustedQty !== 0 && (
            <div className="flex flex-col">
              <div className="mt-3 flex content-center items-center justify-center space-x-3">
                <span>{quantity}</span> <IconChevron size={10} />
                <span>{quantity + adjustedQty}</span>
              </div>
              <Button
                className="mt-3"
                color="secondary"
                onClick={handleAdjustOnClick}
                text={adjustLabel || 'Ajuster la quantité'}
                variant="groove"
              />
            </div>
          )}
        </>
      )}
    </HOCGroup>
  );
};

export default DataProduitsInventaire;
