import { DocumentType, PriceType, ProduitType } from '@innedit/innedit-type';
import { PriceData, ProduitData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Action from '~/components/Message/Action';
import MessageBubble from '~/components/Message/Bubble';
import Notification from '~/components/Notification';
import Price from '~/components/Price';
import svg from '~/images/next-photo.svg';

const Item: FC<{
  data: {
    createdAt: string;
    isAnswer: boolean;
    productId?: string;
    text?: string;
  };
  espaceId: string;
  index: number;
  nbMessages: number;
}> = ({ data, espaceId, index, nbMessages }) => {
  const [product, setProduct] = useState<DocumentType<ProduitType>>();
  const [prices, setPrices] = useState<DocumentType<PriceType>[]>();

  useEffect(() => {
    let isMounted = true;
    const model = new ProduitData({
      espaceId,
    });
    if (data.productId) {
      model
        .findById(data.productId)
        .then(document => {
          if (isMounted) {
            setProduct(document);
            const priceModel = new PriceData({
              espaceId,
              parentCollectionName: 'produits',
              parentId: data.productId,
            });
            priceModel
              .find()
              .then(docs => {
                if (isMounted) {
                  setPrices(docs);
                }

                return isMounted;
              })
              .catch(error => toast.error(error.message));
          }

          return isMounted;
        })
        .catch(error => toast.error(error.message));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const volume =
    Math.round(
      ((product?.width ?? 0) * (product?.height ?? 0) * (product?.depth ?? 0)) /
        1000,
    ) / 1000;

  return (
    <>
      {data.text && <MessageBubble text={data.text} type="recipient" />}
      {product && (
        <Notification
          component={
            <div className="flex w-full flex-col">
              <dl className="grid grid-cols-2 gap-x-3">
                {product.isPhysical && (
                  <>
                    <dt>Dimensions</dt>
                    <dd className="text-right">
                      <span>{product.width}</span>
                      <span>{product.depth}</span>
                      <span>{product.height}</span>
                      <span>
                        {volume} m<sup>3</sup>
                      </span>
                    </dd>
                  </>
                )}
              </dl>
              {prices &&
                prices.length > 0 &&
                prices.map(price => <Price key={price.id} data={price} />)}
              <div className="mt-3 flex justify-end">
                <Action
                  label="Voir le produit"
                  size="sm"
                  to={`/espaces/${product.espaceId}/produits/${product.id}/update`}
                />
              </div>
            </div>
          }
          value={{
            date: product.createdAt,
            description: product.description,
            image:
              product.medias && product.medias.length > 0
                ? `${product.medias[0].origin}/${product.medias[0].pathname}`
                : svg,
            kind: 'produits',
            label: product.label,
            subLabel: product.reference,
          }}
        />
      )}
    </>
  );
};

export default Item;
