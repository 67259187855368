import {
  ContactAttributesType,
  ContactType,
  DocumentType,
} from '@innedit/innedit-type';
import { Link } from 'gatsby';
import compact from 'lodash/compact';
import { Box } from 'packages/formidable';
import { ContactData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { change } from 'redux-form';

import Button from '~/components/Button';
import Editable from '~/datas/Contact/Editable';
import NotEditable from '~/datas/Contact/NotEditable';
import { DataContactProps } from '~/datas/props';
import IconLink from '~/icons/Link';
import capitalize from '~/utils/capitalize';

const DataContact: FC<DataContactProps> = function ({
  editable = true,
  espaceId,
  formName,
  params,
}) {
  const dataForm: Partial<ContactAttributesType> | undefined =
    params && params[formName];
  const [contacts, setContacts] = useState<DocumentType<ContactType>[]>();
  const [contact, setContact] = useState<string>();
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (dataForm) {
      setContact(dataForm.contactId);
      const q =
        dataForm.contactName || dataForm.contactEmail || dataForm.contactPhone;
      if (q) {
        const model = new ContactData({ espaceId });
        model
          .search(q)
          .then(result => {
            if (isMounted) {
              setContacts(result?.hits?.map(({ document }) => document));
            }

            return isMounted;
          })
          .catch(toast.error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [contact, JSON.stringify(dataForm)]);

  const handleAssociateOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    if (undefined !== id && null !== id) {
      dispatch(change(formName, 'contactId', id));
      // TODO ajouter le userId au contact s'il n'existe pas
      setContact(id);
    }
  };

  const handleCreateOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    const contactModel = new ContactData({ espaceId });
    if (dataForm && dataForm.contactName) {
      const [firstName, ...lastNames] = dataForm.contactName.trim().split(' ');
      const newContact = await contactModel.create({
        firstName,
        address: dataForm.contactAddress,
        city: dataForm.contactCity,
        country: dataForm.contactCountry,
        email: dataForm.contactEmail,
        lastName: lastNames.map(capitalize).join(' '),
        phone: dataForm.contactPhone,
        userId: dataForm.userId,
        zip: dataForm.contactZip,
      } as ContactType);

      dispatch(change(formName, 'contactId', newContact.id));
      setContact(newContact.id);
    }
  };

  if (editable) {
    return <Editable formName={formName} params={params} />;
  }

  if (dataForm?.contactId) {
    return null;
  }

  return (
    <>
      {dataForm && <NotEditable datas={dataForm} espaceId={espaceId} />}

      {undefined !== contacts && 0 === contacts.length && (
        <Box className="mt-6 flex flex-row items-center justify-center">
          <Button color="neutral" onClick={handleCreateOnClick} size="sm">
            Créer un contact
          </Button>
        </Box>
      )}

      {undefined !== contacts && contacts.length > 0 && (
        <Box>
          {editable && contact && <div>le contact est associé</div>}
          {!contact && (
            <>
              <h3>Liste des contacts trouvés</h3>
              <ul style={{ listStyle: 'disc' }}>
                {contacts?.map(({ email, id, label, phone }) => (
                  <li key={id} className="flex items-center justify-between">
                    <Link to={`/espaces/${espaceId}/contacts/${id}/update`}>
                      {compact([label, email, phone]).join(' - ')}
                    </Link>
                    {(undefined === contact || contact !== id) && (
                      <Button
                        color="neutral"
                        datas={{
                          'data-id': id,
                        }}
                        iconLeft={IconLink}
                        onClick={handleAssociateOnClick}
                        size="sm"
                        text="Associer"
                        variant="outline"
                      />
                    )}
                    {contact && contact === id && <div>Associé</div>}
                  </li>
                ))}
              </ul>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default DataContact;
